
/**
 * @created by Zheko Hristov on 20/08/2024
 * @last-updated by Zheko Hristov on 21/08/2024
 * 
 * Simple gallery scripts
 **/

 document.addEventListener('turbolinks:before-render', function () {
    (function () {
        'use strict';

    })();
});

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';
        
        if ($('.js-show-gallery').length > 0) {
            let scaleValue = 1;

            // reset zoom
            function resetZoom() {
                scaleValue = 1;
                $('.js-gallery-image').css({ transform: `translate(-50%, -50%) scale(${scaleValue}) translateZ(0)`, 'transform-origin': 'center' });
                $('.gallery__container--outer .js-zoom-out-gallery').addClass('disabled');
                $('.gallery__container--outer .js-zoom-in-gallery').removeClass('disabled');
            }

            // change image source with animation function
            function changeImageSourceWithAnimation(image, source) {
                image.addClass('image--hidden');
                setTimeout(function () {
                    image.attr('src', source);
                    setTimeout(function () {
                        image.removeClass('image--hidden');
                        resetZoom();
                    }, 100);
                }, 300);
            }

            // create array with image source values
            const secondaryImagesSources = [];
            $('.js-secondary-images-single').each(function () {
                secondaryImagesSources.push($(this).attr('data-large-image-source'));
            });

            // if there is only one image use src attr for array item
            if (secondaryImagesSources.length === 0) {
                secondaryImagesSources.push($('.js-show-gallery img').attr('src'))
            }

            let secondaryActiveImageIndex;
            function updateGalleryImageSource() {
                secondaryActiveImageIndex = $('.js-secondary-images-single.active').parent().index();
                if (secondaryActiveImageIndex < 0) {
                    secondaryActiveImageIndex = 0;
                }
                changeImageSourceWithAnimation(
                    $('.js-gallery-image'),
                    secondaryImagesSources[secondaryActiveImageIndex]
                );
            }

            function appendGalleryContainer() {
                const galleryContainer = 
                    `<div class="gallery__container--outer">
                        <i class="fas fa-times fa-2x js-hide-gallery"></i>
                        <i class="fas fa-chevron-left fa-2x js-previous-image ${secondaryImagesSources.length <= 1 ? 'disabled' : ''}"></i>
                        <i class="fas fa-chevron-right fa-2x js-next-image ${secondaryImagesSources.length <= 1 ? 'disabled' : ''}"></i>
                        <div class="gallery__container--image">
                            <div class="single-product__container--image">
                                <img src="" class="js-gallery-image">
                            </div>
                        </div>
                        <div class="gallery__container--zoom">
                            <i class="fas fa-search-minus js-zoom-out-gallery disabled"></i>
                            <i class="fas fa-search-plus js-zoom-in-gallery"></i>
                        </div>
                    </div>`;

                $('body').append(galleryContainer);
            }

            $('.js-show-gallery').on('click', function () {
                appendGalleryContainer();
                updateGalleryImageSource();
            });

            // show previous image in images gallery mode
            $('body').on('click', '.gallery__container--outer .js-previous-image' ,function () {
                if (secondaryImagesSources.length > 1) {
                    if (secondaryActiveImageIndex === 0) {
                        secondaryActiveImageIndex = secondaryImagesSources.length - 1;
                    } else {
                        secondaryActiveImageIndex--;
                    }

                    changeImageSourceWithAnimation(
                        $('.js-gallery-image'),
                        secondaryImagesSources[secondaryActiveImageIndex]
                    );
                }
            });

            // show next image in images gallery mode
            $('body').on('click', '.gallery__container--outer .js-next-image', function () {
                if (secondaryImagesSources.length > 1) {
                    if (secondaryActiveImageIndex === secondaryImagesSources.length - 1) {
                        secondaryActiveImageIndex = 0;
                    } else {
                        secondaryActiveImageIndex++;
                    }

                    changeImageSourceWithAnimation(
                        $('.js-gallery-image'),
                        secondaryImagesSources[secondaryActiveImageIndex]
                    );
                }
            });

            // show previous/next image in images gallery mode with the keyboard arrow keys
            $(document).on('keydown', function (event) {
                if ($('.gallery__container--outer').length > 0) {
                    switch (event.key) {
                        case 'ArrowLeft':
                            $('.js-previous-image').trigger('click');
                            break;
                        case 'ArrowRight':
                            $('.js-next-image').trigger('click');
                            break;
                        case '+':
                            $('.js-zoom-in-gallery').trigger('click');
                            break;
                        case '-':
                            $('.js-zoom-out-gallery').trigger('click');
                            break;
                        case 'Escape':
                            $('.js-hide-gallery').trigger('click');
                            break;
                    }
                }
            });

            // touch start event
            $('body').on('touchstart', '.js-gallery-image', function () {
                // fix for touch screen issue with showing and hiding the address bar
                window.scrollTo(0, 0);
            });

            // reset styles on mouse leave
            // $('body').on('mouseleave touchend', '.js-gallery-image', function () {
            //     $(this).css({ transform: 'scale(1) translateZ(0)' , 'transform-origin': 'center' });
            // });

            // update styles on mouse move
            $('body').on('mousemove touchmove', '.js-gallery-image', function (event) {
                // $(this).css({ transform: 'scale(2) translateZ(0)' });
                if (scaleValue > 1 ) {
                    let coodrinateX = 0;
                    let coordinateY = 0;
                    if (
                        event.type == 'touchstart' ||
                        event.type == 'touchmove' ||
                        event.type == 'touchend' ||
                        event.type == 'touchcancel'
                    ) {
                        let touch = event.originalEvent.touches[0] || event.originalEvent.changedTouches[0];
                        coodrinateX = touch.pageX;
                        coordinateY = touch.pageY;
                    } else if (
                        event.type == 'mousedown' ||
                        event.type == 'mouseup' ||
                        event.type == 'mousemove' ||
                        event.type == 'mouseover' ||
                        event.type == 'mouseout' ||
                        event.type == 'mouseenter' ||
                        event.type == 'mouseleave'
                    ) {
                        coodrinateX = event.clientX;
                        coordinateY = event.clientY;
                    }

                    // This gives you the position of the image on the page
                    var bbox = event.target.getBoundingClientRect();
                    var mouseX = coodrinateX - bbox.left;
                    var mouseY = coordinateY - bbox.top;

                    // Then work out how far through the image as a percentage the mouse is
                    var xPercent = (mouseX / bbox.width) * 100;
                    var yPercent = (mouseY / bbox.height) * 100;

                    // Then we change the `transform-origin` css property on the image to center the zoom effect on the mouse position
                    //event.target.style.transformOrigin = xPercent + '% ' + yPercent + '%';
                    // It's a bit clearer in jQuery:
                    $(this).css('transform-origin', xPercent + '% ' + yPercent + '%');
                    // We add the '%' units to make sure the string looks exactly like the css declaration it becomes.
                }
            });

            // zoom out images gallery mode
            $('body').on('click', '.gallery__container--outer .js-zoom-out-gallery', function () {
                if (scaleValue > 1) {
                    scaleValue -= 0.25;
                    $('.js-gallery-image').css({ transform: `translate(-50%, -50%) scale(${scaleValue}) translateZ(0)` });
                    if ($('.js-zoom-in-gallery').hasClass('disabled')) {
                        $('.js-zoom-in-gallery').removeClass('disabled');
                    }
                    if (scaleValue <= 1) {
                        scaleValue = 1;
                        $(this).addClass('disabled');
                        $('.js-gallery-image').css({ 'transform-origin': 'center' });
                    }
                }
            });

            // zoom in images gallery mode
            $('body').on('click', '.gallery__container--outer .js-zoom-in-gallery', function () {
                if (scaleValue < 2) {
                    scaleValue += 0.25;
                    $('.js-gallery-image').css({ transform: `translate(-50%, -50%) scale(${scaleValue}) translateZ(0)` });
                    if ($('.js-zoom-out-gallery').hasClass('disabled')) {
                        $('.js-zoom-out-gallery').removeClass('disabled');
                    }
                    if (scaleValue >= 2) {
                        scaleValue = 2;
                        $(this).addClass('disabled');
                    }
                }
            });

            // hide images gallery mode
            $('body').on('click', '.gallery__container--outer .js-hide-gallery', function () {
                $('.gallery__container--outer').remove();
            });
        }
    })();
});
